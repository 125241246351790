import { TLocale } from './locale';

// for now, fr-FR url's are redirecting to EN version
// since they currently weren't working too

export const privacyPolicyUrlMap: Record<TLocale, string> = {
  'en-GB': 'https://agrooproject.wixstudio.com/farmoo/en/privacidade-e-cookies',
  'fr-FR': 'https://agrooproject.wixstudio.com/farmoo/en/privacidade-e-cookies',
  'es-ES': 'https://agrooproject.wixstudio.com/farmoo/es/privacidade-e-cookies',
  'pt-PT': 'https://www.agroop.net/privacidade-cookies',
  'pt-BR': 'https://agrooproject.wixstudio.com/farmoo/privacidade-e-cookies',
};

export const termsAndConditionsUrlMap: Record<TLocale, string> = {
  'en-GB': 'https://agrooproject.wixstudio.com/farmoo/en/termos-e-condicoes',
  'fr-FR': 'https://agrooproject.wixstudio.com/farmoo/en/termos-e-condicoes',
  'es-ES': 'https://agrooproject.wixstudio.com/farmoo/es/termos-e-condicoes',
  'pt-PT': 'https://agrooproject.wixstudio.com/farmoo/pt/termos-e-condicoes',
  'pt-BR': 'https://agrooproject.wixstudio.com/farmoo/pt/termos-e-condicoes',
};

export const cookiesPolicyUrlMap: Record<TLocale, string> = {
  'en-GB': 'https://agrooproject.wixstudio.com/farmoo/en/privacidade-e-cookies',
  'fr-FR': 'https://agrooproject.wixstudio.com/farmoo/en/privacidade-e-cookies',
  'es-ES': 'https://agrooproject.wixstudio.com/farmoo/es/privacidade-e-cookies',
  'pt-PT': 'https://www.agroop.net/privacidade-cookies',
  'pt-BR': 'https://agrooproject.wixstudio.com/farmoo/privacidade-e-cookies',
};

export const equipmentListUrlMap: Record<TLocale, string> = {
  'en-GB': 'https://www.agroop.net/tecnologia',
  'fr-FR': 'https://www.agroop.net/tecnologia',
  'es-ES': 'https://www.agroop.net/tecnologia',
  'pt-PT': 'https://www.agroop.net/tecnologia',
  'pt-BR': 'https://www.agroop.net/tecnologia',
};
